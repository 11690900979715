<template>
  <div class="p-field m-0">
    <div class="d-flex align-items-stretch">
      <FormField
        :service="service"
        field="search"
        type="autocomplete"
        @complete="searchMeilisearch($event)"
        :suggestions="resultsMeilisearch"
        :placeholder="$t('Search by Lead, Contract, Client')"
        optionLabel="name"
        optionGroupLabel="name"
        optionGroupChildren="items"
        :completeOnFocus="service.search && service.search.length >= 1 ? true : false"
        :autoOptionFocus="false"
        :selectOnFocus="false"
        margin="m-0"
        panelClass="global-search"
        classField="global-search-panel"
        :isSearchIcon="true"
      >
        <template #optiongroup="{ data }">
          <hr v-if="data.index !== 0" />
          <div class="fs-6">
            {{ indexesLabel(data.item.label) }}
          </div>
        </template>
        <template #item="{ data }">
          <router-link :to="data.item.route + data.item.id">
            <div class="d-flex">
              <div v-html="data.item._formatted.id"></div>
              <div>:&nbsp;</div>
              <div v-html="data.item._formatted.name"></div>
            </div>
            <div v-for="(desc, index) in data.item._formatted.desc" :key="index" class="text-muted fs-7">
              <div v-if="desc" class="d-flex">
                <template v-if="desc.value">
                  <div>{{ index }}</div>
                  <div>:&nbsp;</div>
                  <template v-if="desc.format == 'phone'">
                    {{ $str.phone(desc.value) }}
                    <!-- {{ getFormatValue(desc.value, desc.format) }} -->
                    <!-- {{ desc.value }} -->
                    <!-- <div v-html="desc.value"></div> -->
                  </template>
                </template>
                <template v-else>
                  <div>{{ index }}</div>
                  <div>:&nbsp;</div>
                  <div v-html="desc"></div>
                </template>
              </div>
            </div>
          </router-link>
        </template>
      </FormField>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import FormField from './form/FormField.vue';

export default {
  components: { Button, FormField },
  props: {
    service: Object,
  },
  data() {
    return {
      resultsMeilisearch: null,
    };
  },
  methods: {
    indexesLabel(label) {
      let splitLabel = label.split('_');
      return this.$t(splitLabel[0]);
    },
    async searchMeilisearch(e) {
      setTimeout(async () => {
        this.service.search = e.query;
        let results = await this.service.point();
        this.resultsMeilisearch = results.filter((data) => {
          return data;
        });
      }, 1000);
    },
  },
};
</script>