import Service from '@/services/Service';
import http from '@/utils/http-common';

class UserService extends Service {

    basePath = 'user';

    newObject() {
        return new UserService();
    }

    /**
     * Insert or update item
     * @param {array} attributes 
     * @returns {bool}
     */
    async saveMe(attributes) {
        var request = attributes ? this.prepareRequestAttributes(attributes) : this.prepareRequest();

        return await http.put('/' + this.basePath + '/update-me', request).then(response => {
            this.applyResponse(response);

            return true;
        }).catch(e => this.catchResponse(e));
    }

    async count() {
        return await http.get('/' + this.basePath + '/count', { params: this.prepareRequest() }).then(response => {
            if (response.data.data) {
                return response.data.data.count;
            }
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Get all users
     * 
     * @returns {Service}
    */
    async allSearch() {
        return await http.get('/' + this.basePath + '/all-search/', { params: this.prepareRequest() }).then(response => {
            this.responseData = response.data;
            this.errors = {};

            if (response.data.meta) {
                this.metaTotal = response.data.meta.total;
                this.metaPerPage = response.data.meta.per_page;
            }

            let data = response.data.data;

            return this.collection(data);
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Get item by id
     * 
     * @param {int} id
     * @returns {Service}
    */
    async showSearch(id) {
        id = id || this.id;

        await http.get('/' + this.basePath + '/show-search/' + id, { params: this.prepareRequest() }).then(response => {
            this.applyResponse(response)
        }).catch(e => this.catchResponse(e));

        return this;
    }
}

export default UserService;