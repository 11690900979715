import axios from 'axios';
import Env from './Env';
import Storage from './Storage';
// import router from '../router';

let instance = axios.create({
    baseURL: Env.getItem('VUE_APP_API_URL'),
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + Storage.getItem('jwt-token')
    },
    validateStatus: function (status) {
        return status == 200 || status == 201;
    }
});
instance.interceptors.response.use(null, function (error) {
    if (error.response && error.response.status === 401) {
        Storage.setItem('jwt-token', null);
        window.location.href = '/'; // Пока так, а то через router не работает редирект на авторизацию
        // router.push('/auth');
    }

    return Promise.reject(error);
});

export default instance;